@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html, body {
    background-color: white;
    overflow-x: hidden;
}

.main{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
}

@media only screen and (max-width: 600px) {
    .chipMain {
      line-height:normal !important;
    }
    .botTitle {
        font-size: 1.5rem !important;
    }
    .botSubtitle {
        font-size: 0.7rem !important;
        max-width: 80vw !important;
    }
    .buttonDescTitle {
        font-size: 0.7rem !important;
    }
    .chipButton {
        font-size: 0.7rem !important;
    }
    .chat-loading-h2 {
        font-size: 1rem !important;
    }
    .ai{
        font-size: small !important;
    }
    .user{
        font-size: small !important;
    }
  }

.exchange{
    padding-top: 1vh;
    min-width: 100vw;
    min-height: 70vh;
}

.container{
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* padding-top: 5vh;
    padding-bottom: 2vh; */
}

/* TYPING BAR */
form {
    width: 75vw;
    display: flex;
}

.field{
/*     border: 2px solid #6D6D6D;
    position: relative; */
    width: 80%;
    max-height: 200px;
    /* min-height: calc(12px + 2vh); To match size of expert button */
    resize: none;
    border-radius: 40px;
    font-family: 'Inter', sans-serif;
    text-align: left;
    font-size: medium;
    margin-right: 1%;
    margin-left: 5%;
    vertical-align: center;
    padding: 1.5vh 2vw 1.5vh 3vw;
}

.button{
    border: none;
    background-color: transparent;
    vertical-align: bottom;
    padding: 0;
    width: 5%;
}

.button:hover {
    cursor: pointer; /* Changes the cursor to a hand icon */
  }

.logout-button{
    border: none;
    background-color: transparent;
    vertical-align: bottom;
    padding: 0;
}


/* .expert{
    padding: 2vh 2vw 2vh 2vw;
    background-color: #5CB69E;
    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    vertical-align: 2vh;
    border: none;
    margin-right: 1vw;
    border-radius: 10px;
    text-decoration: none;
    font-size: medium;
} */

.disclaimer {
    font-family: 'Inter', sans-serif;
    color: #787878;
    font-weight: 400;
    font-size: small;
    text-align: center;
    /* margin: 0;
    margin-top: 2vh;
    padding-left:5vh;
    padding-right:5vh;
    text-align: center;
    vertical-align: bottom; */
    line-height: 130%;
    /* text-align: left; */
    margin: 0;
    margin-top: 1.5vh;
    width: 80vw;
}

.learn-more-link{
    color: #5CB69E;
}

/* USER QUESTION */
.question{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
    padding-left: 0%;
}
.userPic{
    height: 45px;
    width: 45px;
    padding-right: calc(10px + 1.5%);
    padding-left: 0.5%; /* These padding numbers are to align the user and AI pic as well as text*/
}

.user{
    border-bottom: solid;
    border-color: #ededed;
    width: 70%;
    display: flex;
    margin-left: 13%;
    font-family: 'Inter', sans-serif;
    font-size: large;
    padding: 1%;
    line-height: 160%;
}

.answer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
}

/* AI RESPONSE */

.aiPic{
    height: 45px; /* Adjusting this height or padding-right may require you to update userPic's dimensions and padding to keep pics and text aligned*/
    padding-right: 2%;
}

.ai{
    border-bottom: solid;
    border-color: #ededed;
    width: 70%;
    display: flex;
    margin-left: 13%;
    font-family: 'Inter', sans-serif;
    font-size: large;
    padding: 1%;
    line-height: 160%;
}

.typingGIF{
    height: 2vh;
    padding: inherit;
}

.aiPicLoading{
    height: 50px;
    padding-right: 1%;
}

/* SOURCE */
.sourceButton{
    background-color: white;
    border: none;
    font-family: 'Inter', sans-serif;
    color: #50B099;
    padding-left: 0;
}

/* EXPERT BUTTON */
.expertButton {
    display: flex;
    align-items: center;
    margin-top: 3vh;
    flex-direction: column;

}

.help {
    font-family: 'Inter', sans-serif;
    color: #787878;
    font-weight: 400;
    font-size: small;
    text-align: center;
}

.expert{
    padding: 1.5vh 1.5vw;
    background-color: #5CB69E;
    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    border-radius: 10px;
    text-decoration: none;
    font-size: small;
    text-align: center;
    max-width: fit-content;
}


/* CHIPS */

.chipMain{
    /* position: relative;
    height: 50vh; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 160%;
/*     margin: auto;
    top: 20vh; */
    /* top: 25vh; */
    justify-content: space-between;
}

.titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;
    margin-top: 10vh;
}

.botTitle{
    font-family: 'Inter', sans-serif;
    color: #787878;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    max-width: 80vw;
    margin: 0;
}

.botSubtitle {
    font-family: 'Inter', sans-serif;
    color: #787878;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    margin: 0;
}

.buttons {
    /* margin-top: 20vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonRow{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    max-width: 90vw;
}

.buttonDescTitle {
    font-family: 'Inter', sans-serif;
    color: #787878;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    margin: 0;
    margin-bottom: 1vh;
    text-align: center;
    max-width: 80vw;
}

.chipButton{
    background-color: white;
    border: 0.8px solid #C3C3C3;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    color: #787878;
    padding: 2%;
    margin: 0.5%;
    width: 30%;
    border-radius: 15px;
    text-align: left;
}

.chipButton:hover{
    cursor: pointer;
}

#about {
    color: #50B099;
}

#example {
    color: #4068AE;
}

#limit {
    color: #DB4836;
}

/* LOGIN AND SIGN UP PAGE*/

.loginContainer {
    display: flex;
    margin: 3vh;
    justify-content: space-evenly;
    
    @media only screen and (min-device-width: 1100px) {
        height: 84vh;
    }

    @media only screen and (min-device-width: 800px) and (max-device-width: 1100px) {
        flex-direction: column-reverse;
        align-items: center;
    }

    @media only screen and (max-device-width: 799px) {
        flex-direction: column;
        align-items: center;
        
    }
  }

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media only screen and (min-device-width: 800px) {
        width: 48%;
        height: 100%;
    }

    @media only screen and (max-device-width: 799px) {
        width: 80%;
        height: fit-content;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }
  }

.loginTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginTitle {
    margin-top: 6.5vh;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    color: #000000;

    @media only screen and (max-device-width: 799px) {
        margin-top: 10vh;
    }
}

.signUpTitle {
    margin-top: 3vh;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    color: #000000;

    @media only screen and (max-device-width: 799px) {
        margin-top: 10vh;
    }
}

.loginSubtitle {
    font-family: 'Inter', sans-serif;
    color: #4D4D4D;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1vh;
    text-align: center;
}

.checkbox{
    display: flex;
    align-items: center;
    margin: 3vh;
}

.checkboxTitle{
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #4D4D4D;
}

.loginButton{
    background-color: #50B099;
    border-radius: 40px;
    border-color: transparent;
    padding: 2vh 10vw;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-bottom: 3vh;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
}

.demo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    width: 48%;
    height: 92%;

    @media only screen and (min-device-width: 800px) {
        /* width: 48%;
        height: 100%; */
    }

    @media only screen and (max-device-width: 799px) {
        display: none;
    }
}

.demoMobile {
    @media only screen and (min-device-width: 800px) {
        display: none;
    }
    @media only screen and (max-device-width: 799px) {
        display: inline;
        width: 80%;
    }
}

.demoImg {
    width: 100%;
    height: 100%;
    border: 1px solid #4D4D4D; /* Adjust the border properties as needed */
    border-radius: 20px;
    @media only screen and (max-device-width: 799px) {
        display: inline !important;
    }
    @media only screen and (min-device-width: 800px) {
        display: none !important;
    }
}

.demoVid {
    max-height: 100%; 
    max-width: 100%;
    border: 1px solid #4D4D4D; /* Adjust the border properties as needed */
    border-radius: 20px;

    @media only screen and (max-device-width: 799px) {
        display: none !important;
    }
}

.fairOppImage{
    margin-right: 2vw;
    cursor: pointer;
    width: 50%;
}

.signInButton {
    padding: 2vh 2vw; /* Shortened padding shorthand */
    background-color: transparent;
    border: 1px solid #50B099;
    color: #50B099;
    font-family: 'Inter', sans-serif;
    border-radius: 10px;
    cursor: pointer;
    white-space: pre-line; /* Allow text to wrap */
    text-align: center; /* Center the text */
    line-height: 150%; /* Limit maximum width for smaller screens */
    font-size: 1rem;
    text-decoration: none;
    width: 100%;
  }

  .signInButtonMobile {
    color: #50B099;
    font-family: 'Inter', sans-serif;
    text-align: center;
    padding-bottom: 25%;
  }
  
.forgotPasswordLink{
    color: #4D4D4D;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    margin-top: 2vh;

    @media only screen and (min-device-width: 800px) {
        margin-right: 1vw;
        align-self: flex-end;
    }
}

/* FORGOT PASSWORD PAGE*/
.forgotPasswordContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 3vh 4vw;
}

.balloonImage{
    margin-top: 15vh;
    width: 12%;
    height: 12%;
}

.forgotPasswordTitle{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1.8rem;
    color: #000000;
}

.forgotButtons{
    display: flex;

    @media only screen and (min-device-width: 800px) {
        flex-direction: row;
        gap: 2vw;
    }

    @media only screen and (max-device-width: 799px) {
        flex-direction: column;
        gap: 2vh;
    }
    
}

.sendEmail{
    display: flex;
    align-items: center;
    background-color: #50B099;
    border-radius: 40px;
    border-color: transparent;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    justify-content: center;

    @media only screen and (min-device-width: 800px) {
        padding: 2vh 2vw;
    }

    @media only screen and (max-device-width: 799px) {
        padding: 2vh 10vw;
    }
}

.login{
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    border: 1px solid #666666;
    color: #666666;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    padding: 2vh 4vw;
    justify-content: center;
}
  
/* Chat Loading  Page */
.chat-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-family: 'Inter', sans-serif;
    text-align: center;
    padding-top: 15vh;
    flex-wrap: wrap;
    background-color: white;
}

.chat-loading-logo{
    height: calc(15vh + 80px);
}

.chat-loading-dots{
    height: calc(3vh + 20px);
    margin-right: 45vw;
    margin-left: 45vw;
    margin-top: 5vh;
}

.chat-loading-h2{
    background-color: white;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #000000;

}

/*HEADER*/
body{
    margin-left: 0;
    margin-right: 0;
}

.header {
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 1vw;
    border-bottom: 1px solid #C3C3C3;
    width: 100vw;
    background-color: white;
    height: 8vh;
}

.learn-more-button{
    vertical-align: bottom;
    padding: 0;
}

.learn-more-button button{
    border-color: transparent;
    margin-right: 2vw;
    background-color: #5CB69E;
    font-size: medium;
    font-family: 'Inter', sans-serif;
    padding: 0.9vw 4vw;
    color: #FFFFFF;
    border-radius: 10px;
    cursor: pointer;
}

.side-by-side-buttons{
    display: flex;
    align-items: center;
}
  
.about-us{
    font-family: 'Inter', sans-serif;
    color: #000000;
    text-decoration: none;
    margin-right: 4vw;
}

.logo img{
    margin-left: 2vw;
    cursor: pointer;
    background-color: transparent;

}

.logout-button{
    margin-right: 4vw;
    border: transparent;
    background-color: transparent;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}

form {
    display: flex;
    height: 100%;
    width: 100%;
}

.header-fairOppLogo {
    height: 5vh;
}

/* RESET LINK SUCCESSFULLY SENT PAGE */
.resetLinkSentMain{
    margin: 3vh 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resetLinkSent{
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    color: #5CB69E;
}
.resetLinkSentDesc{
    margin-top: -2%;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: #C3C3C3;
    text-align: center;
}

/* RESET LINK INVALID PAGE */
.resetLinkFailureMain{
    margin: 3vh 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resetLinkFailure{
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    color: #DB4836;
}
.resetLinkFailureDesc{
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: #C3C3C3;
    text-align: center;
}